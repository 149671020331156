import { BiUtils, LinkUtils, LocationManager, PlatformUtils, SessionServiceAPI, WixCodeNamespacesRegistry } from '@wix/thunderbolt-symbols'
import { AppsPublicApiManager } from './appsPublicApiManager'

export function BuildPlatformUtils({
	linkUtils,
	sessionService,
	appsPublicApiManager,
	wixCodeNamespacesRegistry,
	biUtils,
	locationManager
}: {
	linkUtils: LinkUtils
	sessionService: SessionServiceAPI
	appsPublicApiManager: AppsPublicApiManager
	wixCodeNamespacesRegistry: WixCodeNamespacesRegistry
	biUtils: BiUtils
	locationManager: LocationManager
}): PlatformUtils {
	return {
		linkUtils,
		sessionService,
		appsPublicApisUtils: {
			getPublicAPI: appsPublicApiManager.getPublicApi
		},
		wixCodeNamespacesRegistry,
		biUtils,
		locationManager
	}
}
