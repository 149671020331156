import { PlatformEnvData, PlatformLogger } from '@wix/thunderbolt-symbols'
import { clientModuleFetcher, createClientSAC, toClientSACFactoryParamsFrom } from 'thunderbolt-site-assets-client'
import { platformFedopsMetricsReporter } from '@wix/thunderbolt-commons'

export const siteAssetsClientWorkerAdapter = (platformEnvData: PlatformEnvData, logger: PlatformLogger) => {
	const fetchFn = self.fetch
	const {
		location: { rawUrl },
		site: {
			experiments,
			mode: { qa }
		},
		siteAssets: {
			clientTopology,
			manifests,
			tbElementsManifests,
			clientInitParams: {
				siteAssetsClientConfig: { isStagingRequest },
				deviceInfo
			}
		}
	} = platformEnvData

	return createClientSAC(
		toClientSACFactoryParamsFrom({
			siteAssets: platformEnvData.siteAssets,
			env: 'clientWorker',
			deviceInfo,
			qa,
			experiments,
			requestUrl: rawUrl,
			fetchFn,
			isStagingRequest,
			moduleFetcher: clientModuleFetcher(
				fetchFn,
				clientTopology,
				{
					thunderbolt: manifests,
					tbElements: tbElementsManifests
				},
				'webWorker'
			),
			siteAssetsMetricsReporter: platformFedopsMetricsReporter(logger)
		})
	)
}
