import { Address, ContactInfo, Email, Phone } from '../../types'

export interface ContactInfoServerDTO {
	info: {
		name?: {
			first?: string
			last?: string
		}
		company?: string
		jobTitle?: string
		locale?: string
		birthdate?: string
		picture?: {
			image: {
				url?: string
			}
			imageProvider?: string
		}
		emails?: {
			items: Array<Email>
		}
		phones?: {
			items: Array<Phone>
		}
		addresses?: {
			items: Array<Address>
		}
		locations?: {
			items: Array<string>
		}
		labelKeys?: {
			items: Array<string>
		}
		extendedFields?: {
			items: object
		}
	}
}

export const mapContactToServerDTO = (contactInfo: ContactInfo): ContactInfoServerDTO => {
	return (
		contactInfo && {
			info: {
				name: contactInfo.name,
				company: contactInfo.company,
				jobTitle: contactInfo.jobTitle,
				locale: contactInfo.locale,
				birthdate: contactInfo.birthdate,
				picture:
					contactInfo.picture?.image || contactInfo.picture?.imageProvider
						? {
								image: {
									url: contactInfo.picture.image,
								},
								imageProvider: contactInfo.picture.imageProvider,
						  }
						: undefined,
				emails: contactInfo.emails && {
					items: contactInfo.emails,
				},
				phones: contactInfo.phones && {
					items: contactInfo.phones,
				},
				addresses: contactInfo.addresses && {
					items: contactInfo.addresses,
				},
				locations: contactInfo.locations && {
					items: contactInfo.locations,
				},
				labelKeys: contactInfo.labelKeys && {
					items: contactInfo.labelKeys,
				},
				extendedFields: contactInfo.extendedFields && {
					items: contactInfo.extendedFields,
				},
			},
		}
	)
}
